/** @jsxImportSource theme-ui */
import * as React from "react";
import { Container } from "theme-ui";

import CTA from "../components/cta";
import Image from "../components/image";

const OrganisationFeatures = ({ data }) => {
  const { text, cta, benefits, anchor } = data;
  return (
    <Container id={anchor}>
      <div
        sx={{
          width: ["100%", "100%", "70%"],
          mt: "4rem",
        }}
      >
        <h2
          dangerouslySetInnerHTML={{ __html: text }}
          sx={{
            mb: "1rem",
          }}
        />
        {/* eslint-disable-next-line */}
        <CTA cta={cta} />
      </div>
      <div
        sx={{
          display: "flex",
          flexWrap: "wrap",
          mt: "6rem",
        }}
      >
        {benefits?.map(({ _key, text, image }) => (
          <div key={_key} sx={{ width: ["100%", "100%", "50%"], mb: "4rem" }}>
            <div
              sx={{
                display: "flex",
                justifyContent: ["center", "center", "start"],
              }}
            >
              <Image
                sx={{
                  height: "6rem",
                  width: "6rem",
                  // borderRadius: "100%",
                  margin: ["0 auto", "0 auto", "0 0 1.25rem"],
                }}
                source={image}
              ></Image>
            </div>
            <p
              dangerouslySetInnerHTML={{ __html: text }}
              sx={{
                fontSize: ["1.2rem", "1.2rem", "1.5rem"],
                width: ["70%"],
                margin: ["0 auto", "0 auto", "unset"],
                textAlign: ["center", "center", "left"],
                fontWeight: "medium",
              }}
            />
          </div>
        ))}
      </div>
    </Container>
  );
};

export default OrganisationFeatures;
